@import 'src/assets/styles/mixins/_breakpoints.scss';

$totalValueBoxHeight: 165px;
$totalValueBoxHeightMobile: 150px;

.totalValueBox {
	position: relative;
	z-index: 105;
	width: 100%;
	height: $totalValueBoxHeightMobile;
	background-color: var(--color-white);
	border-bottom: 1px solid var(--color-light-neutral);
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	@include bp-min(sm) {
		height: $totalValueBoxHeight;
	}

	.monthlyRateText {
		text-align: center;
	}

	.monthlyRate {
		text-align: center;
		display: inline-flex;
		flex-direction: row;
		align-items: center;
		gap: 0.5rem;
	}

}

.detailsWrapper {
	width: 100%;
	height: auto;
	background-color: var(--color-white);
	position: absolute;
	top: -100%;
	left: 0;
	z-index: 104;
	transition: top 0.3s;

	padding: $totalValueBoxHeightMobile + 15px 15px 30px;

	@include bp-min(sm) {
		padding: $totalValueBoxHeight + 30px 30px 30px;
	}

	&.detailsWrapperActive {
		top: 0;
	}

	.dataRow {
		width: 100%;
		margin-bottom: 1.5rem;
		height: 30px;
		display: flex;
		flex-direction: row;
		gap: 0.25rem;
	}

	.dataRowSpacer {
		width: 100%;
		margin-bottom: 1.85rem;
		border-bottom: 1px solid var(--color-light-neutral);
		display: flex;
		flex-direction: row;
		gap: 0.25rem;
	}
}

.wishWrapper {
	//position: relative;
	padding: 15px 15px;
	margin: 0;
	z-index: 1;

	@include bp-min(sm) {
		padding: 30px 30px 20px 30px;
	}

	&.isPutBack {
		z-index: 1;
	}

	.coverageButton {
		width: 300px;
		height: 80px;
		background-color: var(--color-sky);
		border-radius: 5px;

		.coverageText {
			font-size: 16px;
			color: var(--color-dark-neutral);
		}
	}

	.rangeSlider {

		width: 100%;
		margin-bottom: 30px;
		opacity: 1;

		.rangeLabel {
			font-size: 14px;
			color: var(--color-dark-neutral);
		}

		&.isFade {
			opacity: 0;
			animation: fadeIn 1.5s ease-out 0.7s 1 forwards;
		}

	}

	.totalWrapper {

		width: 100%;
		height: auto;
		overflow: hidden;
		position: relative;
		padding-top: 2.5rem;
		padding-left: 6px;
		padding-right: 6px;
		margin-left: -6px;
		margin-right: -6px;

		.totalBox {

			width: 100%;
			border-radius: var(--border-radius-small);
			background-color: var(--color-light-turquoise);
			padding: 1rem;

			display: flex;
			flex-direction: row;
			position: relative;
			font-size: 14px;
			color: var(--color-dark-neutral);

			.totalBoxText {
				display: flex;
				justify-content: center;
				flex-direction: column;
				text-align: left;
				flex-grow: 1;
			}

			.totalAmount {
				display: flex;
				flex-shrink: 0;
				justify-content: center;
				flex-direction: column;
				font-size: 24px;
				text-align: right;
			}
		}

	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.runtimeWrapper {
	//position: relative;
	z-index: 2;
	padding: 15px;
	margin: 0;

	&.isOnTop {
		position: relative;
		z-index: 101;
	}

	@include bp-min(sm) {
		padding: 30px 30px 20px 30px;
	}

	.coverageButton {
		width: 300px;
		height: 80px;
		background-color: var(--color-sky);
		border-radius: 5px;

		.coverageText {
			font-size: 16px;
			color: var(--color-dark-neutral);
		}
	}

	.rangeSlider {
		width: 100%;
		margin-bottom: 30px;
		opacity: 1;

		.rangeLabel {
			font-size: 14px;
			font-weight: 600;
			color: var(--color-dark-neutral);
		}

		&.isFade {
			opacity: 0;
			animation: fadeIn 1.5s ease-out 0.7s 1 forwards;
		}
	}
}

.netIncomeWrapper {
	position: relative;
	z-index: 1;
	padding: 30px 30px 20px 30px;
	opacity: 1;
	border-bottom: 1px solid var(--color-light-neutral);
	border-top: 1px solid var(--color-light-neutral);

	@include bp-max(xs) {
		padding: 15px;
	}

	&.incomeWrapperRuntimePage {
		padding: 30px 0 20px;
	}

	&.isFade {
		opacity: 0;
		animation: fadeIn 1.5s ease-out 0.7s 1 forwards;
	}

	.netIncomeText {
		font-size: 14px;
		color: var(--color-dark-neutral);
		margin-bottom: 10px;
	}

	.iconEdit {
		position: absolute;
		width: 24px;
		height: 24px;
		top: 40px;
		right: 30px;
		fill: var(--color-darker-neutral);
		cursor: pointer;

		&.isHide {
			display: none;
		}
	}

	.iconCross {
		position: absolute;
		width: 10px;
		height: 10px;
		top: 46px;
		right: 30px;
		fill: var(--color-darker-neutral);
		cursor: pointer;

		&.isHide {
			display: none;
		}
	}

	.netIncome {
		font-size: 20px;
		line-height: 1.6;
		color: var(--color-darker-neutral);
		margin-top: 5px;
		display: block;

		&.isHide {
			display: none;
		}
	}

	.netIncomeInputWrapper {
		font-size: 20px;
		line-height: 1.6;
		flex-wrap: wrap;

		&.isShow {
			display: flex;
			justify-content: space-between;
		}

		& > div {
			// width: 49%;
		}

		div:nth-child(3) {
			width: 100%;
		}

		.smallInput {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
}

.submitbuttonWrapper {
	padding: 15px 15px 30px;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.rateWrapper {
	position: relative;
	z-index: 1;
	padding: 30px 30px 20px 30px;
	margin: 0;
	// border-bottom: 1px solid #deedf2;
	// border-top: 1px solid #deedf2;
	display: flex;
	opacity: 1;

	@include bp-max(sm) {
		padding: 15px;
	}

	&.isFade {
		opacity: 0;
		animation: fadeIn 1.5s ease-out 0.7s 1 forwards;
	}

	.rateButtonWrapper {
		width: 50px;
		height: 30px;
		border-radius: 20px;
		background-color: var(--color-medium-sky);
		position: relative;
		transition: all 0.3s ease-out;
		cursor: pointer;

		.rateButton {
			position: absolute;
			width: 26px;
			height: 26px;
			border-radius: 20px;
			background-color: var(--color-white);
			top: 2px;
			left: 2px;
			transition: all 0.3s ease-out;
		}

		&.isActive {
			background-color: var(--color-turquoise);

			.rateButton {
				position: absolute;
				left: 20px;
			}
		}
	}

	.rateText {
		font-size: 14px;
		margin-left: 10px;
		margin-top: 5px;
	}
}

.buttonWrapper {

	position: relative;
	z-index: 1;
	padding: 10px 0 30px;
	margin: 0;
	display: flex;
	justify-content: space-between;

	.button {
		width: 100%;
		padding-left: 15px !important;
		padding-right: 15px !important;
	}
}

.extraCopy {
	font-size: 12px;
	line-height: 1.375;
	font-weight: 400;
	color: var(--color-dark-neutral);

	@include bp-min(xs) {
		font-size: 14px;
	}
}

.boxLabel {
	font-size: 16px;
}

.boxRate {
	font-size: 40px;
	font-weight: 300;
	line-height: 1.3;
}

.spacer {
	flex-grow: 1;
}

.inlineTooltipIcon {
	display: inline-block;
	flex-grow: 0;
	flex-shrink: 0;
	margin-top: -2px;
}
