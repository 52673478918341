@import 'src/assets/styles/mixins/_breakpoints.scss';
@import 'src/assets/styles/mixins/_color.scss';

.icon {

	width: 80px;
	height: 80px;
	border-radius: 50%;
	position: relative;
	background-color: var(--color-sky);
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 10px;

	svg {
		width: 70%;
		height: 70%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		fill: var(--color-darker-sky);
	}

}

.buttonRow {

	margin-top: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;

	@include bp-min(sm) {
		flex-direction: row;
	}

	button {

		margin-bottom: 0 !important;

		&:not(:last-child) {
			margin-right: 120px;
			margin-bottom: 10px !important;
		}

	}

}

.exitWindowPopupTrigger {

	width: 100vw;
	height: 20px;
	position: fixed;
	top: 0;
	left: 0;
	display: none;
	z-index: 9999999998;

	@include bp-min(sm) {
		display: block;
	}

}
