@import 'src/assets/styles/mixins/_breakpoints.scss';

.wrapper {

	height: 100%;

	@include bp-max(sm) {
		width: 100%;
	}

}

.row {
	height: 100%;
	position: relative;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	gap: 1rem;
	align-items: center;
	-webkit-tap-highlight-color: transparent;

	@include bp-min(sm) {
		gap: 1.5rem;
	}
}

.spacer {

	display: none;

	@include bp-max(sm) {
		display: block;
		flex-grow: 1;
	}

}

.value {

	p {
		@include bp-max(sm) {
			font: var(--typescale-body-font);
		}
	}

}

.editButton {

	background-color: transparent;
	padding: 0;
	border: 0;
	outline: 0;
	box-shadow: none;
	font: inherit;
	width: var(--icon-size-m);
	height: var(--icon-size-m);
	border-radius: var(--border-radius-small);

	&:focus,
	&:focus-visible {
		box-shadow: 0 1px 6px 0 rgba(68, 68, 68, 0.00), 0 0 0 2px #FFF, 0 0 0 4px var(--color-focus);
	}

	svg {
		width: var(--icon-size-m);
		height: var(--icon-size-m);
		fill: var(--color-red);
	}

}
