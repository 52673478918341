@import 'src/assets/styles/mixins/_breakpoints.scss';

.button {

	position: relative;
	display: inline-flex;
	flex-direction: row;
	gap: 0.25rem;
	border: none;
	outline: none;
	box-shadow: none;
	cursor: pointer;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	overflow: hidden;
	text-decoration: none;

	&.disabled {
		cursor: not-allowed;
	}

	.text {
		position: relative;
		z-index: 2;
		display: block;
		text-align: center;
		white-space: nowrap;
		pointer-events: none;
	}

	.prependIcon,
	.appendIcon {

		position: relative;
		z-index: 2;
		display: block;
		width: var(--icon-size-m);
		height: var(--icon-size-m);
		flex-shrink: 0;
		pointer-events: none;

		svg {
			width: var(--icon-size-m);
			height: var(--icon-size-m);
		}

	}

	&.xs {

		height: 2rem;
		min-width: 7rem;
		padding: 0 1rem;
		border-radius: 1rem;

		.text {
			font: 0.65rem/1rem var(--font-family-paragraph);
			font-weight: var(--typescale-strong-weight);
		}

		&.round {
			min-width: auto;
			width: 2rem;
			padding: 0;
		}

	}

	&.s {

		height: 2rem;
		min-width: 7rem;
		padding: 0 1rem;
		border-radius: 1rem;

		.text {
			font: var(--typescale-body-font);
			font-weight: var(--typescale-strong-weight);
		}

		&.round {
			min-width: auto;
			width: 2rem;
			padding: 0;
		}

	}

	&.m {

		height: 3rem;
		min-width: 8.5rem;
		padding: 0 1.25rem;
		border-radius: 1.5rem;

		.text {
			font: var(--typescale-body-large-font);
			font-weight: var(--typescale-strong-weight);
		}

		&.round {
			min-width: auto;
			width: 3rem;
			padding: 0;
		}

	}

	&.primary {

		color: var(--color-white);
		background-color: var(--color-purple);

		svg {
			fill: var(--color-white);
		}

		&:hover {

			@include bp-min(sm) {
				background-color: var(--color-dark-purple);
			}

		}

	}

	&.secondary {

		color: var(--color-white);
		background-color: var(--color-turquoise);

		svg {
			fill: var(--color-white);
		}

		&:hover {

			@include bp-min(sm) {
				background-color: var(--color-dark-turquoise);
			}

		}

	}

	&.primary,
	&.secondary {

		&.disabled {

			color: var(--color-light-neutral);
			background-color: var(--color-lighter-neutral);

			svg {
				fill: var(--color-light-neutral);
			}

		}

		&:focus,
		&:focus-visible {
			box-shadow: 0 1px 6px 0 rgba(68, 68, 68, 0.00), 0 0 0 2px #FFF, 0 0 0 4px var(--color-focus);
		}

	}

	&.link,
	&.linkFlat {

		color: var(--color-purple);
		background-color: transparent;
		border-radius: var(--border-radius-small);

		svg {
			fill: var(--color-purple);
		}

		&:hover {

			@include bp-min(sm) {

				color: var(--color-dark-purple);

				svg {
					fill: var(--color-dark-purple);
				}

			}

		}

		&.xs,
		&.s,
		&.m {
			padding: 0;
			min-width: 0;
		}

		&.disabled {

			color: var(--color-light-neutral);

			svg {
				fill: var(--color-light-neutral);
			}

		}

		&:focus,
		&:focus-visible {
			box-shadow: 0 1px 6px 0 rgba(68, 68, 68, 0.00), 0 0 0 2px #FFF, 0 0 0 4px var(--color-focus);
		}

	}

	&.linkFlat {

		&.xs,
		&.s,
		&.m {
			height: auto;
		}

	}

}

.loadingAnimation {

	display: flex;
	flex-direction: row;
	text-align: center;
	margin-left: auto;
	margin-right: auto;

	& > span {
		display: inline-block;
		width: 0.75rem;
		height: 0.75rem;
		background-color: var(--color-white);
		border-radius: 50%;
		margin-left: 0.125rem;
		margin-right: 0.125rem;
		animation: buttonLoadingAnimation 1.4s infinite ease-in-out both;
	}

	& > .loadingAnimationBounce1 {
		animation-delay: -0.32s;
	}

	& > .loadingAnimationBounce2 {
		animation-delay: -0.16s;
	}

}

@keyframes buttonLoadingAnimation {
	0%,
	80%,
	100% {
		transform: scale(0);
	}
	40% {
		transform: scale(1);
	}
}
