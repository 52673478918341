@import 'src/assets/styles/mixins/_breakpoints.scss';

.icon {

	width: var(--icon-size-m);
	height: var(--icon-size-m);
	display: inline-block;
	cursor: pointer;

	svg {
		width: var(--icon-size-m);
		height: var(--icon-size-m);
		fill: var(--color-purple);
	}

}

.iconTooltip {

	background-color: transparent;
	padding: 0;
	border: 0;
	outline: 0;
	box-shadow: none;
	font: inherit;
	border-radius: 50%;
	width: var(--icon-size-m);
	height: var(--icon-size-m);

	&:focus,
	&:focus-visible {
		box-shadow: 0 1px 6px 0 rgba(68, 68, 68, 0.00), 0 0 0 2px #FFF, 0 0 0 4px var(--color-focus);
	}

}
