.input {

	cursor: pointer;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;

	&::-ms-expand {
		display: none;
	}

	&.inputUseIcons {
		text-indent: calc(var(--icon-size-m) + 0.75rem);
	}

}

.arrow {

	position: absolute;
	top: 50%;
	right: 0.75rem;
	transform: translate(0, -50%);
	z-index: 3;
	pointer-events: none;
	width: var(--icon-size-m);
	height: var(--icon-size-m);

	svg {
		width: var(--icon-size-m);
		height: var(--icon-size-m);
		display: inline-block;
		fill: var(--color-turquoise);
		transition: 0.2s;
	}

}

.icon {
	position: absolute;
	top: 50%;
	left: 0.75rem;
	transform: translate(0, -50%);
	z-index: 3;
	pointer-events: none;
	width: var(--icon-size-m);
	height: var(--icon-size-m);
}
