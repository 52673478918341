.input {

	-webkit-appearance: none;
	appearance: none;
	width: 100%;
	height: 2px;
	border-radius: var(--border-radius-small);
	background: var(--color-light-neutral);
	outline: none;
	-webkit-transition: .2s;
	transition: opacity .2s;
	cursor: pointer;

	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		width: var(--icon-size-m);
		height: var(--icon-size-m);
		border-radius: 50%;
		background: var(--color-turquoise);
		cursor: pointer;
	}

	&::-moz-range-thumb {
		width: var(--icon-size-m);
		height: var(--icon-size-m);
		border-radius: 50%;
		background: var(--color-turquoise);
		cursor: pointer;
	}

	&:focus,
	&:focus-visible {

		&::-webkit-slider-thumb {
			-webkit-appearance: none;
			appearance: none;
			width: var(--icon-size-m);
			height: var(--icon-size-m);
			border-radius: 50%;
			background: var(--color-turquoise);
			cursor: pointer;
			box-shadow: 0 1px 6px 0 rgba(68, 68, 68, 0.00), 0 0 0 2px #FFF, 0 0 0 4px var(--color-focus);
		}

		&::-moz-range-thumb {
			width: var(--icon-size-m);
			height: var(--icon-size-m);
			border-radius: 50%;
			background: var(--color-turquoise);
			cursor: pointer;
			box-shadow: 0 1px 6px 0 rgba(68, 68, 68, 0.00), 0 0 0 2px #FFF, 0 0 0 4px var(--color-focus);
		}

	}

}
