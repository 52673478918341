.wrapper {

	position: relative;
	padding-left: 65px;

	div > svg {
		position: absolute;
		top: -2px;
		left: 0;
		fill: var(--color-medium-neutral);
		width: 50px;
		height: 50px;
	}

}
