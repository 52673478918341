@import '../../../assets/styles/mixins/breakpoints';
@import '../../../assets/styles/mixins/color';

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999999999;
	@include overlay-background;
}

.content {

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 95%;
	max-height: 90%;
	border: none;
	overflow: auto;
	outline: none;

	padding: 50px 15px 15px;
	background-color: var(--color-white);
	box-shadow: var(--box-shadow-default);
	border-radius: var(--border-radius-small);

	@include bp-min(sm) {
		padding: 50px 25px;
	}

	@include bp-min(lg) {
		width: 65%;
		padding: 60px 45px 45px;
	}


	&.smallWidth {

		max-width: 415px;
		padding: 50px 15px 15px;

		@include bp-min(sm) {
			padding: 50px 20px 20px;
		}

		@include bp-min(lg) {
			padding: 60px 30px 30px;
		}

	}

}

.closeButton {

	background-color: transparent;
	padding: 0;
	border: 0;
	outline: 0;
	box-shadow: none;
	font: inherit;
	position: absolute;
	top: 20px;
	right: 20px;
	width: var(--icon-size-m);
	height: var(--icon-size-m);
	border-radius: var(--border-radius-small);
	cursor: pointer;

	&:focus,
	&:focus-visible {
		box-shadow: 0 1px 6px 0 rgba(68, 68, 68, 0.00), 0 0 0 2px #FFF, 0 0 0 4px var(--color-focus);
	}

	svg {
		width: var(--icon-size-m);
		height: var(--icon-size-m);
	}

}
