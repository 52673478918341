@import 'src/assets/styles/mixins/_breakpoints.scss';

.tooltipRow {
	display: flex;
	flex-direction: row;
	gap: 0.25rem;
}

.closeIcon {

	display: inline-block;
	width: var(--icon-size-m);
	height: var(--icon-size-m);
	cursor: pointer;
	background-color: transparent;
	padding: 0;
	border: 0;
	outline: 0;
	box-shadow: none;
	font: inherit;
	border-radius: var(--border-radius-small);

	svg {
		cursor: pointer;
		fill: var(--color-darker-neutral);
	}

	&:focus,
	&:focus-visible {
		box-shadow: 0 1px 6px 0 rgba(68, 68, 68, 0.00), 0 0 0 2px #FFF, 0 0 0 4px var(--color-focus);
	}

}


.upsellAmount {

	&,
	p {
		font-size: 16px;
		font-weight: 700;
		color: var(--color-darker-neutral);

		@include bp-min(sm) {
			font-size: 18px;
		}
	}

}

.upsellAmountValue {

	font-size: 16px;
	font-weight: 700;
	text-align: right;
	margin-right: 0;
	color: var(--color-turquoise);

	@include bp-min(sm) {
		font-size: 22px;
	}

}

.upsellRuntimeValue {

	font-size: 16px;
	font-weight: 700;
	text-align: right;
	margin-right: 0;

	@include bp-min(sm) {
		font-size: 22px;
	}

}
