@import 'src/assets/styles/mixins/_breakpoints.scss';

.popup {
	width: 100%;
	position: relative;
	padding-bottom: 10px;
}

.popupHeader {

	display: flex;
	flex-direction: row;

	&:not(:first-child) {
		margin-top: 30px;
	}

}

.popupHeaderIcon {

	flex-grow: 0;
	padding-right: 15px;

	svg {
		width: 40px;
		height: auto;
	}

}

.popupHeadline {
	position: relative;
	font-size: 18px !important;
	color: var(--color-darker-neutral) !important;
}

.popupText {

	margin-top: 15px !important;
	margin-bottom: 20px !important;

	ul {

		list-style-type: none;
		padding-left: 0;

		li {

			padding-left: 15px;
			position: relative;

			&:before {
				content: '';
				width: 5px;
				height: 5px;
				border-radius: 50%;
				background-color: var(--color-purple);
				position: absolute;
				top: 7px;
				left: 0;
			}

		}
	}

	p, li {

		font-size: 12px !important;
		line-height: 1.375 !important;
		font-weight: 400 !important;
		color: var(--color-dark-neutral) !important;

		@include bp-min(sm) {
			font-size: 14px !important;
		}

	}

}
