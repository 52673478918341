.custom-tooltip {

	border-radius: var(--border-radius-default) !important;
	padding: 1rem;
	max-width: 300px;
	z-index: 99999999999 !important;
	background-color: var(--color-white) !important;
	box-shadow: none;
	border: 1px solid var(--color-light-neutral) !important;
	pointer-events: all !important;
	opacity: 1 !important;
	text-align: left;
	cursor: text;

	&,
	* {

		font: var(--typescale-body-font) !important;
		color: var(--color-darker-neutral) !important;

		strong {
			font-weight: var(--typescale-strong-weight) !important;
		}

	}

	@include bp-min(sm) {
		max-width: 400px;
	}

	ul {

		padding-left: 15px;

		li {
			padding-left: 0;
			list-style-type: disc;
		}

	}

}

.custom-tooltip-arrow {
	background-color: var(--color-white) !important;
	border-color: var(--color-light-neutral) !important;
	border-style: solid !important;
	border-bottom-width: 1px !important;
	border-right-width: 1px !important;
}
