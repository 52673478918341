@import 'src/assets/styles/mixins/_breakpoints.scss';

h2.headline {

	margin: 20px 0 10px 0;

	font-size: 26px;

	@include bp-min(md) {
		font-size: 32px;
	}

	@include bp-min(lg) {
		font-size: 40px;
	}

}

h3.subHeadline {

	font-size: 16px;
	margin: 0 0 20px 0;
	color: var(--color-dark-neutral);

	@include bp-min(sm) {
		font-size: 14px;
	}

}

.infoRow {
	& > div {
		display: flex;
		align-items: center;

		& > div {
			margin: 15px;
			flex: 5;

			svg {
				margin: 6px 1px 0 0;
				height: 12px;
			}

			& > div {
				display: flex;

				& > svg {
					flex: 1;
				}

				& > p {
					flex: 20;
					margin: 2px 0;
				}
			}
		}

		& > svg {

			margin: 0;
			width: 60px;
			height: auto;
			flex: 1.5;

			@include bp-min(sm) {
				margin: 10px 0 10px 10px;
				width: 91px;
				flex: 1;
			}

		}

		p {
			font-size: 12px;
			margin: 0;

			@include bp-min(sm) {
				font-size: 14px;
			}
		}
	}

	.hr {
		height: 2px;
		background-color: var(--color-sky);
		border: none;
	}
}

.xs2aForm1 {
	position: absolute !important;
	top: -9999999px;
	left: -999999px;
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
}

.popup {

	text-align: left;

	@include bp-min(sm) {
		max-width: 578px;
	}

	.content {
		font-size: 14px;
	}

	[class*='list'] {
		display: inline-block;
		padding-top: 7px;
	}

}

.anchorSubpage {
	position: relative;
	top: -80px;
}

.tinkLogo {

	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	font-size: 9px;

	img {
		margin-left: 10px;
		width: 50px;
		height: auto;
	}

}
